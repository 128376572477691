import type { NextPage } from "next";
import Head from "next/head";
import {
	AnimatePresence,
	domAnimation,
	LazyMotion,
	m as motion,
} from "framer-motion";
import parse from "html-react-parser";
import Navigation from "@/components/Navigation/Navigation";
import { Form } from "@/components/Home/Form/Form";
import { Usps } from "@/components/Home/Usps/Usps";
import { ThreeSteps } from "@/components/Home/ThreeSteps/ThreeSteps";
import { WhoAreWe } from "@/components/Home/WhoAreWe/WhoAreWe";
import { WineCarousel } from "@/components/Home/WineCarousel/WineCarousel";
import HomeIntro from "@/components/Home/Intro/Intro";
import { getFrontPage, getFeaturedImage, getNavigation } from "@/api/functions";
import { HomePage } from "@/models/page";
import { WP_Image } from "@/models/image";
import dynamic from "next/dynamic";
import WithSWRConfig from "@/layouts/WithSWRConfig";
import { PAGE_TRANSITION } from "@/utils/framer/animation";

const DynamicShoppingCart = dynamic(() => import("@/components/ShoppingCart"), {
	ssr: false,
});

interface Props {
	page: HomePage;
	featuredImage?: WP_Image;
	fallback: {
		[x: string]: any;
	};
}

export async function getServerSideProps() {
	// Fetch front page
	const page = await getFrontPage();
	const navigation = await getNavigation();

	// Fetch front page illustration
	const featuredImage = page?.featured_media
		? await getFeaturedImage(page?.featured_media)
		: null;

	return {
		props: {
			page,
			featuredImage,
			fallback: {
				"/wsw/v1/menu": navigation,
			},
		} as Props, // will be passed to the page component as props
	};
}

const Home: NextPage<Props> = ({ page, featuredImage, fallback }) => {
	const yoastHead = parse(page.yoast_head!);
	return (
		<WithSWRConfig fallback={fallback}>
			<Head>{yoastHead}</Head>

			<Navigation left="logo" right="menu" />
			<LazyMotion features={domAnimation}>
				<AnimatePresence exitBeforeEnter>
					<motion.main {...PAGE_TRANSITION}>
						<HomeIntro data={page.acf} featuredImage={featuredImage} />
						{page.acf.layouts &&
							page.acf.layouts.map((layout, index) => {
								switch (layout.acf_fc_layout) {
									case "how_it_works":
										return <ThreeSteps key={index} {...layout} />;
									case "highlighted_wines":
										return (
											<WineCarousel
												key={index}
												title={layout.title}
												content={layout.content}
												wines={layout.highlighted_wines}
											/>
										);
									case "usps":
										return <Usps key={index} {...layout} />;
									case "the_story":
										return <WhoAreWe key={index} {...layout} />;
									case "newsletter":
										return <Form key={index} {...layout} />;
									default:
										return null;
								}
							})}
					</motion.main>
				</AnimatePresence>
			</LazyMotion>
			<DynamicShoppingCart />
		</WithSWRConfig>
	);
};

export default Home;
