import { LinkButton } from "@/components/Button";
import { Icon } from "@/components/Icon/Icon";
import { FlexColumn, FlexRow } from "@/components/layout/FlexBox";
import { Heading } from "@/components/Typography";
import { USPSLayout } from "@/models/layouts";
import { prefetch } from "@/utils/prefetch";

import s from "./Usps.module.scss";

export function Usps({ title, list }: USPSLayout) {
	return (
		<section className={s.section}>
			<FlexColumn gap={32}>
				<Heading as="h2" sizeMobile="md" sizeDesktop="md" className={s.heading}>
					{title}
				</Heading>
				<FlexColumn gap={21}>
					{list.map(({ item }, index) => (
						<FlexRow key={index} gap={16} alignItems="center">
							<div className={s.icon}>
								<Icon icon="check-circle"></Icon>
							</div>
							<Heading as="h4" sizeMobile="xs" sizeDesktop="xs">
								{item}
							</Heading>
						</FlexRow>
					))}
				</FlexColumn>
				<LinkButton
					href="/vragenlijst"
					rank="primary"
					icon="arrow-right"
					onMouseEnter={() => {
						prefetch("/wsw/v1/questionnaire");
					}}
				>
					Kies je wijn
				</LinkButton>
			</FlexColumn>
		</section>
	);
}
