import { useEffect, useRef } from "react";
import cx from "classnames";
import { Icon } from "@/components/Icon/Icon";

import s from "./ScrollIndicator.module.scss";

interface Props {
	gradientClassName?: string;
	containerClassName?: string;
}

export function ScrollIndicator({
	gradientClassName,
	containerClassName,
}: Props) {
	const ref = useRef<HTMLDivElement | null>(null);
	const useOnScroll = () => {
		if (
			window.innerHeight + window.pageYOffset >=
			document.body.offsetHeight - 80
		) {
			ref.current?.classList.add("hidden");
		} else {
			ref.current?.classList.remove("hidden");
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", useOnScroll);

		return () => {
			window.removeEventListener("scroll", useOnScroll);
		};
	});

	return (
		<>
			<div className={cx(s.el, gradientClassName)}></div>
			<div ref={ref} className={cx(s.container, containerClassName)}>
				<span className={s.indicator}>
					<Icon icon="arrow-left" />
					<span>scroll</span>
				</span>
			</div>
		</>
	);
}
