import { useEffect, useState } from "react";
import cx from "classnames";
import Link from "next/link";
import Logo from "./Logo";
import Menu from "../Menu/Menu";

import { IconButton } from "../Button";
import router from "next/router";

import s from "./Navigation.module.scss";

let lastScrollTop = 0;

interface Props {
	left?: "logo" | "previous-question" | "wine-overview";
	right?: "menu" | "close";
	className?: string;
}

export default function Navigation({ left, right, className }: Props) {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const nav = document.getElementsByTagName("NAV")[0];
		const onScroll = () => setOffset(window.pageYOffset);
		window.addEventListener("scroll", onScroll, { passive: true });

		// when to show the navigation and add the background color
		if (offset > lastScrollTop && offset > 100) {
			nav.classList.add("hide-nav");
		} else if (offset > 100) {
			nav.classList.add("scroll-up");
			nav.classList.remove("hide-nav");
		} else {
			nav.classList.remove("hide-nav", "scroll-up");
		}

		// Update lastScrollTop and disable negative offset on mobile
		lastScrollTop = offset <= 0 ? 0 : offset;

		return () => window.removeEventListener("scroll", onScroll);
	});

	return (
		<nav className={cx(s.navigation, className)}>
			{left && showLeftSide(left)}
			{right && showRightSide(right)}
		</nav>
	);
}

function showLeftSide(left: string) {
	switch (left) {
		case "logo":
			return (
				<div className={s.navigation__logo}>
					<Link href="/">
						<a title="Home - WeShareWine">
							<Logo />
						</a>
					</Link>
				</div>
			);
		case "previous-question":
			return (
				<IconButton
					onClick={() => router.back()}
					rank="primary"
					icon="arrow-left"
					label="Vorige vraag"
					reverse={true}
					showLabel={true}
				>
					Vorige vraag
				</IconButton>
			);
		case "wine-overview":
			return (
				<IconButton
					onClick={() => router.back()}
					rank="primary"
					icon="arrow-left"
					label="Wijnoverzicht"
					reverse={true}
					showLabel={true}
				>
					Wijnoverzicht
				</IconButton>
			);
		default:
			return null;
	}
}

function showRightSide(right: string) {
	switch (right) {
		case "menu":
			return <Menu />;
		case "close":
			return (
				<Link passHref href={"/"}>
					<IconButton label="Sluiten" rank="primary" icon="close"></IconButton>
				</Link>
			);
		default:
			return null;
	}
}
