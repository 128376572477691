import { useState, useEffect } from "react";

/**
 * 	useMatchMedia hook
 * based on: https://gist.github.com/donaldpipowitch/b454bbfbc3c81a6708a915086cc0de31
 * @param query any valid media query string. Example: (min-width: 1024px)
 * @returns boolean
 */
export function useMatchMedia(query: string): boolean | undefined {
	// Do not set an initial state to prevent inconsistencies with server and client render
	const [matches, setMatches] = useState<boolean | undefined>();

	useEffect(() => {
		const mediaQueryList = matchMedia(query);

		// Directly execute a first time to fix the 'missing' initial state.
		setMatches(mediaQueryList.matches);

		const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

		if (mediaQueryList.addEventListener) {
			mediaQueryList.addEventListener("change", onChange);
		} else {
			mediaQueryList.addListener(onChange);
		}

		return () => {
			if (mediaQueryList.removeEventListener) {
				mediaQueryList.removeEventListener("change", onChange);
			} else {
				mediaQueryList.removeListener(onChange);
			}
		};
	}, [query]);

	return matches;
}

export function useMatchMediaDesktop(): boolean | undefined {
	return useMatchMedia("(min-width: 48em)");
}
