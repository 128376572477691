import { FlexColumn } from "@/components/layout/FlexBox";
import { Heading, Text } from "@/components/Typography";
import { WP_Wysiwyg } from "@/models/wordpress";
import { homepageWine } from "@/models/wine";

import HtmlParser from "@/components/HtmlParser";

import s from "./WineCarousel.module.scss";
import { ResultItem } from "@/components/ResultPage/ResultItem/ResultItem";

interface Props {
	title: string;
	content: WP_Wysiwyg;
	wines: homepageWine[];
}

export function WineCarousel({ title, content, wines }: Props) {
	return (
		<section className={s.section}>
			<FlexColumn gap={32}>
				<Heading as="h2" sizeMobile="lg" sizeDesktop="xl" className={s.heading}>
					{title}
				</Heading>

				<div className={s.content}>
					<Text className={s.introText}>
						<HtmlParser content={content} />
					</Text>
					<div className={s.wines}>
						{wines.map((wine) => {
							return <ResultItem key={wine.id} wine={wine} />;
						})}
					</div>
				</div>
			</FlexColumn>
		</section>
	);
}
