import { LinkButton } from "@/components/Button";
import { FlexColumn } from "@/components/layout/FlexBox";
import { Heading } from "@/components/Typography";
import { WhoAreWeLayout } from "@/models/layouts";

import s from "./WhoAreWe.module.scss";

export function WhoAreWe({ cite, link }: WhoAreWeLayout) {
	return (
		<section className={s.section}>
			<FlexColumn gap={28}>
				<Heading as="h3" sizeMobile="lg" sizeDesktop="lg" className={s.heading}>
					{cite}
				</Heading>
				<LinkButton
					href={link.url}
					target={link.target}
					rank="tertiary"
					icon="arrow-right"
					className={s.button}
				>
					{link.title}
				</LinkButton>
			</FlexColumn>
		</section>
	);
}
