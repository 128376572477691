import { LinkButton } from "@/components/Button";
import { FlexColumn } from "@/components/layout/FlexBox";
import { Heading, Text } from "@/components/Typography";
import { prefetch } from "@/utils/prefetch";

import s from "./ThreeSteps.module.scss";

interface Props {
	steps: { title: string; content: string }[];
}

export function ThreeSteps({ steps }: Props) {
	return (
		<section className={s.section}>
			<Heading as="h2" sizeDesktop="xl" sizeMobile="lg" className={s.heading}>
				Zo werkt het in <br /> 3 stappen
			</Heading>
			<div className={s.content}>
				{steps.map((step, index) => (
					<FlexColumn key={step.title} gap={30} className={s.step}>
						<div className={s.stepNumber}>
							<Heading as="h3" sizeDesktop="sm" sizeMobile="sm">
								{index + 1}
							</Heading>
						</div>
						<FlexColumn gap={16}>
							<Heading as="h3" sizeDesktop="lg" sizeMobile="md">
								{step.title}
							</Heading>
							<Text>{step.content}</Text>
						</FlexColumn>
						{index === 0 && (
							<LinkButton
								href="/vragenlijst"
								rank="primary"
								icon="arrow-right"
								onMouseEnter={() => {
									prefetch("/wsw/v1/questionnaire");
								}}
							>
								Kies je wijn
							</LinkButton>
						)}
					</FlexColumn>
				))}
			</div>
		</section>
	);
}
