import { PropsWithChildren } from "react";
import { SWRConfig } from "swr";

type Props = PropsWithChildren<{
	fallback: {
		[x: string]: any;
	};
}>;

export default function WithSWRConfig({ children, fallback }: Props) {
	return <SWRConfig value={{ fallback }}>{children}</SWRConfig>;
}
