import { LinkButton } from "@/components/Button";
import { Heading, Text } from "@/components/Typography";

import { useBreakpoint } from "@/hooks/use-breakpoint";
import { GenericID } from "@/models/api";
import { WP_Image } from "@/models/image";
import { WP_Wysiwyg } from "@/models/wordpress";
import HtmlParser from "@/components/HtmlParser";
import { prefetch } from "@/utils/prefetch";
import { ScrollIndicator } from "@/components/ScrollIndicator/ScrollIndicator";

import s from "./Intro.module.scss";

interface Props {
	data: {
		title: WP_Wysiwyg;
		content: WP_Wysiwyg;
		illustration: GenericID;
		button: any;
	};
	featuredImage?: WP_Image;
}

export default function HomeIntro({ data, featuredImage }: Props) {
	const isBreakpointLG = useBreakpoint("lg");
	return (
		<header className={s.section}>
			<div className={s.content__wrapper}>
				<div className={s.content}>
					<div>
						<Heading
							as="h1"
							sizeMobile="lg"
							sizeDesktop="xxl"
							className={s.title}
						>
							<HtmlParser content={data.title} />
						</Heading>
						<div className={s.text}>
							<Text>
								<HtmlParser content={data.content} />
							</Text>
						</div>
					</div>

					<LinkButton
						href={data.button.url}
						rank="primary"
						icon="arrow-right"
						onMouseEnter={() => {
							prefetch("/wsw/v1/questionnaire");
						}}
					>
						{data.button.title}
					</LinkButton>

					{isBreakpointLG && (
						<ScrollIndicator
							gradientClassName={s.scroll_gradientDesktop}
							containerClassName={s.scroll_indicatorDesktop}
						/>
					)}
				</div>
			</div>
			{!isBreakpointLG && (
				<ScrollIndicator
					gradientClassName={s.scroll_gradient}
					containerClassName={s.scroll_indicator}
				/>
			)}
			{featuredImage && (
				<div
					className={s.image}
					style={{ backgroundImage: `url('${featuredImage.source_url}')` }}
				></div>
			)}
		</header>
	);
}
