import { mutate } from "swr";

export function prefetch(string: string) {
	const baseUrl = new URL("wp-json" + string, process.env.NEXT_PUBLIC_API_URL);

	mutate(
		string,
		fetch(baseUrl.href).then((res) => res.json())
	);
	// the second parameter is a Promise
	// SWR will use the result when it resolves
}
