export const PAGE_TRANSITION = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	transition: {
		duration: 0.5,
	},
};
